import {
  Dialog,
  Field,
  FormGroup,
  Input,
  NotificationVariants,
  useDynamicCallback,
  useGlobalToasts,
  type DialogProps,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { useSubAccountReconRequests } from '../../useSubAccountReconRequests';
import type { ReconMatchRow } from './reconMatchRows';

type AddCommentDialogProps = {
  match: ReconMatchRow | undefined;
} & DialogProps;

export const AddCommentDialog = ({ match, ...dialogProps }: AddCommentDialogProps) => {
  const { add: addToast } = useGlobalToasts();
  const [comment, setComment] = useState(new Field<string>().setIsRequired(true));
  const { addCommentToBreak } = useSubAccountReconRequests();

  // When the form is opened, reset the comment field
  useEffect(() => {
    if (dialogProps.isOpen) {
      setComment(curr => curr.updateValue('').setTouched(false).validate());
    }
  }, [dialogProps.isOpen]);

  const handleConfirm = useDynamicCallback(() => {
    if (!match || !comment.value) {
      return;
    }

    addCommentToBreak(match.ID, comment.value)
      .then(() => {
        addToast({
          variant: NotificationVariants.Positive,
          text: 'Successfully added comment',
        });
      })
      .catch((e: Error) => {
        addToast({
          variant: NotificationVariants.Negative,
          text: `Failed to add comment: ${e.message}`,
        });
      });
  });

  return (
    <Dialog
      {...dialogProps}
      confirmDisabled={comment.hasError}
      title="Add Comment"
      onConfirmMouseOver={() => setComment(curr => curr.setTouched(true))}
      minWidth="350px"
      stretchButtons
      onConfirm={handleConfirm}
    >
      <FormGroup label="Comment" error={comment.errorString}>
        <Input
          value={comment.value ?? ''}
          autoComplete="off"
          onChange={e => setComment(curr => curr.updateValue(e.target.value).validate())}
          data-testid="add-comment-to-break-input"
          invalid={comment.isTouched && comment.hasError}
        />
      </FormGroup>
    </Dialog>
  );
};
