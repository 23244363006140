import {
  Box,
  Checkbox,
  MixpanelEventProperty,
  NotificationVariants,
  useGlobalToasts,
  useMixpanel,
  type MixpanelEvent,
} from '@talos/kyoko';
import { FormRow } from 'components/Form';

export function TradingSettingCheckbox({
  id,
  isEnabled,
  mixPanelEvent,
  toastDisplayName,
  onChecked,
  displayName,
  displayDescription,
}: {
  id: string;
  isEnabled: boolean;
  onChecked: (enabled: boolean) => void;
  mixPanelEvent: MixpanelEvent;
  toastDisplayName?: string;
  displayName: React.ReactNode;
  displayDescription: React.ReactNode;
}) {
  const mixpanel = useMixpanel();
  const { add } = useGlobalToasts();

  return (
    <div>
      <FormRow>
        <Checkbox
          id={id}
          name={id}
          checked={isEnabled}
          onChange={e => {
            mixpanel.track(mixPanelEvent, {
              [MixpanelEventProperty.Enabled]: e.target.checked,
            });
            onChecked(e.target.checked);
            add({
              text: `${toastDisplayName ?? displayName} ${e.target.checked ? 'enabled' : 'disabled'}.`,
              variant: NotificationVariants.Positive,
            });
          }}
        >
          {displayName}
        </Checkbox>
      </FormRow>
      <Box ml="spacingLarge">{displayDescription}</Box>
    </div>
  );
}
