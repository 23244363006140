import { type BlotterTableClientLocalFilter, type Transfer, useMarketAccountsContext } from '@talos/kyoko';
import type { IRowNode } from 'ag-grid-community';
import { useFeatureFlag } from 'hooks';
import { useCallback } from 'react';

/** Additionally to any existing filters, when 'enableAccountSegregation' is active,
 * filtering out transfers that are not associated with visible market accounts
 */
export function useClientSideTransfersFilter(
  clientSideFilter: BlotterTableClientLocalFilter<Transfer>
): BlotterTableClientLocalFilter<Transfer> {
  const { enableAccountSegregation } = useFeatureFlag();
  const { marketAccountsByID } = useMarketAccountsContext();
  return useCallback(
    (row: IRowNode<Transfer>) => {
      const data = row.data;
      const clientSideFilterResult = clientSideFilter(row);

      if (!enableAccountSegregation) {
        return clientSideFilterResult;
      }
      if (!clientSideFilterResult || !data) {
        return false;
      }

      // if the transfer is associated with any known market account, it should be visible, otherwise it should be hidden
      const isFromMarketAccountVisible =
        data.FromMarketAccountID != null && marketAccountsByID.has(data.FromMarketAccountID);
      const isToMarketAccountVisible = data.ToMarketAccountID != null && marketAccountsByID.has(data.ToMarketAccountID);
      return isFromMarketAccountVisible || isToMarketAccountVisible;
    },
    [clientSideFilter, enableAccountSegregation, marketAccountsByID]
  );
}
