import type {
  CUSTOMER_ADDRESS,
  CUSTOMER_EXECUTION_REPORT,
  CUSTOMER_EXECUTION_RULE,
  CUSTOMER_FIX_CONNECTION,
  CUSTOMER_MAX_ORDER_SIZE_LIMIT,
  CUSTOMER_QUOTE,
  CUSTOMER_TRADE,
  CUSTOMER_TRANSACTION,
  CUSTOMER_USER,
  CUSTOMER_USER_API_KEY,
  Customer,
  CustomerOrder,
  CustomerQuote,
  CustomerRole,
  CustomerTrade,
  CustomerTradingLimit,
  CustomerTransaction,
  CustomerUser,
  CustomerUserApiKey,
  CustomerUserRole,
  DateFnInput,
  ICustomerAddress,
  ICustomerFIXConnection,
  ICustomerOrderExecutionRule,
  ITreasuryAccountLink,
  Order,
  SubscriptionResponse,
  TREASURY_ACCOUNT_LINK,
} from '@talos/kyoko';
import type { LegacyQuote } from 'components/OMS/NewRFQ/Components/Quotes/Quote/types';
import type { Observable } from 'rxjs';
export enum FixedPricingModeEnum {
  Price = 'Price',
  Spread = 'Spread',
  Unset = '',
}

export interface CustomerTradeUpdate {
  Counterparty: string;
  Symbol: string;
  Side: string;
  Currency: string;
  Quantity: string;
  Price: string;
  Amount: string;
  AmountCurrency: string;
  SalesCommission?: string;
  ClTradeID: string;
  MarketAccount: string;
  TradeID?: string;
  Comments?: string;
  RiskSubAccount?: string;
  Fee?: string;
  FeeCurrency?: string;
  TransactTime?: string;
}

export interface CustomerTransferProps {
  amount: string;
  clientID?: string;
  currency: string;
  description: string;
  fromMarketAccountID: string;
  market: string;
  reference: string;
  toMarketAccountID: string;
  transactionID: string;
  transactTime?: Date;
  chainCurrency?: string;
}

export interface CustomersContextProps {
  customers: Observable<Customer[]>;
  customerUsers: Observable<CustomerUser[]>;
  customerRoles: Observable<CustomerRole[]>;
  customerQuoteSub: Observable<CustomerQuoteResponse>;
  customerQuotesObs: Observable<CustomerQuote[]>;
  customerQuotesByRFQID: Observable<Map<string, CustomerQuote>>;
  customerAddressesByCustomerAddressID: Observable<Map<string, ICustomerAddress>>;
  approvePendingApprovalTransaction: (
    customerTransaction: CustomerTransaction
  ) => Promise<CustomerApprovedTransactionResponse>;
  approvePendingTransferTransaction: (
    customerTransaction: CustomerTransaction,
    txhash?: string
  ) => Promise<CustomerApprovedTransactionResponse>;
  initiatePendingTransferTransaction: (
    customerTransfer: CustomerTransferProps
  ) => Promise<CustomerApprovedTransactionResponse>;
  rejectPendingApprovalTransaction: (
    customerTransaction: CustomerTransaction
  ) => Promise<CustomerApprovedTransactionResponse>;
  rejectPendingTransferTransaction: (
    customerTransaction: CustomerTransaction
  ) => Promise<CustomerApprovedTransactionResponse>;
  createCustomerUser: (customerUser: Partial<CustomerUser>) => Promise<CustomerUserResponse>;
  createCustomerTrade: (customerTrade: CustomerTradeUpdate) => Promise<CustomerTradeResponse>;
  updateCustomerTrade: (customerTrade: CustomerTradeUpdate) => Promise<CustomerTradeResponse>;
  updateCustomerUser: (customerUser: Partial<CustomerUser>) => Promise<CustomerUserResponse>;
  updateCustomerUserRole: (
    customerUserID: string,
    initialRoles?: string[],
    newRoles?: string[]
  ) => Promise<CustomerUserRoleResponse>;
  modifyCustomerOrderSpread: (orderID: string, spread: string) => Promise<CustomerOrdersResponse>;
  getOrderExecutionRulesGlobalStatus: () => Promise<boolean>;
  updateOrderExecutionRulesGlobalStatus: (status: 'enable' | 'disable') => Promise<boolean>;
  getCustomerRoles: () => Promise<any>;
  getCustomerConfigs: () => Promise<any>;
  getCustomerUserConfigs: (customerUserID: string) => Promise<CustomerUserConfig[]>;
  getEffectiveLinkForCustomerTransaction: (entity: CustomerTransaction) => Promise<TreasuryLinkResponse>;
  removeCustomerUserConfig: (customerUserID: string, configKey: string) => Promise<null>;
  upsertCustomerUserConfigs: (
    configs: Array<CustomerUserConfig & { CustomerUserID: string }>
  ) => Promise<CustomerUserConfig[] | null>;
  upsertCustomerUserConfig: <T>(customerUserID: string, configKey: string, value: T) => Promise<CustomerUserConfig[]>;
  getCustomerUserAPIKeys: (userID: string) => Promise<CustomerUserAPIKeyResponse>;
  createCustomerUserAPIKey: (userID: string) => Promise<CustomerUserAPIKeyResponse>;
  deleteCustomerUserAPIKey: (userID: string, apiKeyID: string) => Promise<CustomerUserAPIKeyResponse>;
  updateCustomerUserAPIKey: (customerUserAPIKey: CustomerUserApiKey) => Promise<CustomerUserAPIKeyResponse>;
  createCustomerTradingLimit: (
    customerUserTradingLimit: CustomerTradingLimit
  ) => Promise<CustomerUserTradingLimitResponse>;
  updateCustomerTradingLimit: (
    customerUserTradingLimit: CustomerTradingLimit
  ) => Promise<CustomerUserTradingLimitResponse>;
  deleteCustomerTradingLimit: (tradingLimitID: string) => Promise<CustomerUserAPIKeyResponse>;
  getCustomerExecutionRules: () => Promise<CustomerExecutionRuleResponse>;
  createCustomerExecutionRule: (
    customerUserExecutionRule: ICustomerOrderExecutionRule
  ) => Promise<CustomerExecutionRuleResponse>;
  editCustomerExecutionRule: (
    customerUserExecutionRule: ICustomerOrderExecutionRule
  ) => Promise<CustomerExecutionRuleResponse>;
  deleteCustomerExecutionRule: (ruleID: string) => Promise<CustomerExecutionRuleResponse>;
  getCustomerFIXConnections: () => Promise<CustomerFIXConnectionResponse>;
  upsertCustomerFIXConnection: (
    customerFIXConnection: Partial<ICustomerFIXConnection>[]
  ) => Promise<CustomerFIXConnectionResponse>;
  deleteCustomerFIXConnection: (connectionID: string) => Promise<CustomerFIXConnectionResponse>;
  getCustomerWithdrawAddress: (customerAddressID: string) => Promise<CustomerAddressResponse>;
  createCustomerAddress: (customerAddress: Partial<ICustomerAddress>) => Promise<CustomerAddressResponse>;
  addMarketAccountAuthorization: (customerUserID: string, authorizations: MarketAccountAuthorization[]) => Promise<any>;
  deleteMarketAccountAuthorization: (
    customerUserID: string,
    authorizations: MarketAccountAuthorization[]
  ) => Promise<any>;
}
export interface MarketAccountAuthorization {
  marketAccount: number;
  authorization: 'read' | 'write' | 'admin';
}

export interface CustomersProviderProps {
  customerExecutionReportsByRFQIDObs: Observable<Map<string, Order>>;
  requestQuote: (quote: Partial<LegacyQuote>) => void;
  currentQuote: CustomerQuote;
  cancelQuote: (counterparty: string, rFQID: string, transactTime?: DateFnInput) => void;
  acceptQuote: (quote: Partial<LegacyQuote>) => void;
  lockQuotePrice: (params: { quoteReqID: string; customerBidPrice: string; customerOfferPrice: string }) => void;
  lockQuoteSpread: (params: { quoteReqID: string; bidSpread: string; offerSpread: string }) => void;
}

export interface CustomerQuoteResponse {
  type: typeof CUSTOMER_QUOTE;
  address: typeof CUSTOMER_QUOTE;
  initial: boolean;
  ts: string;
  data: CustomerQuote[];
}

export interface CustomerUserResponse {
  type: typeof CUSTOMER_USER;
  initial: boolean;
  ts: string;
  data: CustomerUser[];
}

export interface CustomerAddressResponse {
  type: typeof CUSTOMER_ADDRESS;
  initial: boolean;
  ts: string;
  data: ICustomerAddress[];
}

export interface CustomerUserRoleResponse {
  type: typeof CUSTOMER_USER;
  initial: boolean;
  ts: string;
  data: CustomerUserRole[];
}

export interface CustomerApprovedTransactionResponse {
  type: typeof CUSTOMER_TRANSACTION;
  initial: boolean;
  ts: string;
  data: CustomerTransaction[];
}

export interface CustomerUserAPIKeyResponse {
  type: typeof CUSTOMER_USER_API_KEY;
  initial: boolean;
  ts: string;
  data: CustomerUserApiKey[];
}

export interface CustomerExecutionRuleResponse {
  type: typeof CUSTOMER_EXECUTION_RULE;
  initial: boolean;
  ts: string;
  data: ICustomerOrderExecutionRule[];
}

export interface CustomerFIXConnectionResponse {
  type: typeof CUSTOMER_FIX_CONNECTION;
  initial: boolean;
  ts: string;
  data: ICustomerFIXConnection[];
}

export interface CustomerUserTradingLimitResponse {
  type: typeof CUSTOMER_MAX_ORDER_SIZE_LIMIT;
  initial: boolean;
  ts: string;
  data: CustomerTradingLimit[];
}

export interface CustomerTradeResponse {
  type: typeof CUSTOMER_TRADE;
  initial: boolean;
  ts: string;
  data: CustomerTrade[];
}

export interface CustomerOrdersResponse {
  type: typeof CUSTOMER_EXECUTION_REPORT;
  initial: boolean;
  ts: string;
  data: CustomerOrder[];
}

export interface TreasuryLinkResponse {
  type: typeof TREASURY_ACCOUNT_LINK;
  ts: string;
  data: Partial<ITreasuryAccountLink>[];
}

export interface LockQuotePriceParams {
  quoteReqID: string;
  customerBidPrice: string;
  customerOfferPrice: string;
}

export interface LockQuoteSpreadParams {
  quoteReqID: string;
  bidSpread: string;
  offerSpread: string;
}

export type CustomerUserConfig = CustomerUser['Config'][number];
export type CustomerUserConfigResponse = SubscriptionResponse<CustomerUserConfig>;
