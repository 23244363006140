import { createSelector } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import type { AppConfigState } from 'providers/AppConfigProvider/AppConfigProvider.types';
import type { AppState } from 'providers/AppStateProvider/types';

/** Retrieve the full stored AppConfig for subsequent storage/display */
export const selectFullAppConfig = createSelector(
  (state: AppState) => state.appConfig.nonReduxConfig.configState,
  // HACK: Release 2.50 has redux code creating a new prop 'blotters3', in the new redux slice, but we don't want to store it in the user's app_config.
  // - The new AppConfig state storage model is unit tested and beneficial for moving AppConfig concepts to redux, just nothing is used within it yet for this release.
  nonReduxConfig => {
    return omit(nonReduxConfig, 'blotters3') as AppConfigState;
  }
);

/** Retrieve the AppConfig Blotters3 state by a given id */
export const selectBlotters3StateById = createSelector(
  [selectFullAppConfig, (_, blotterId: string) => blotterId],
  (fullAppConfig: ReturnType<typeof selectFullAppConfig>, blotterId: string) => {
    return fullAppConfig.blotters3[blotterId];
  }
);
