import { compact } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages } from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList, areEqual, type ListChildComponentProps } from 'react-window';
import { BehaviorSubject, combineLatest, type Observable } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import { useUserContext } from '../../contexts';
import { useConstant, useDynamicCallback, useIntl, useJsonModal, useObservableValue } from '../../hooks';
import { ExecutionReport, type ExecTypeEnum } from '../../types';
import { IconButton } from '../Button';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { LoaderTalos } from '../LoaderTalos';
import { Text } from '../Text';
import { TimelineGroup, TimelineItem } from '../Timeline';
import { TimelineScrollOverlay } from '../Timeline/TimelineScrollOverlay';
import { Tooltip } from '../Tooltip';
import { EmptyDataWrapper, ItemWrapper, ItemsWrapper } from './styles';
import type {
  ExecutionTimelineEntry,
  ExecutionTimelineGroupEntry,
  ExecutionTimelineItemEntry,
  ExecutionTimelineProps,
  WithTimestamp,
} from './types';
import {
  flattenOpenedGroups,
  getTimelineItemData,
  getTimestampText,
  groupSimilarTimelineItems,
  sortTimelineItems,
} from './utils';

const messages = defineMessages({
  showNumHiddenTrades: {
    defaultMessage: 'Show <textColor>{numHiddenTrades}</textColor> hidden trades',
    id: 'ExecutionTimeline.showNumHiddenTrades',
  },
});

/**
 * Helps determine if a ExecutionReport item should be saved as previous.
 * Ideally, we'd like to compare against `PendingReplace`, but the key `Markets` on reports of that exectype
 * carries data that it wishes to write, causing it to not represent the past truth of markets.
 */
const EXEC_TYPE_ENUM_HOLDS_PAST_TRUTH: { [key in keyof typeof ExecTypeEnum]?: boolean } = {
  Replaced: true,
  New: true,
  PendingReplace: false,
};

function isWithTimestamp<T extends { timestamp: string | undefined }>(
  maybeWithTimeStamp: Partial<T>
): maybeWithTimeStamp is T {
  return !!maybeWithTimeStamp.timestamp;
}

const DEFAULT_ROW_HEIGHT = 38;
export function ExecutionTimeline({
  groupSimilarItems = true,
  showInitiatorLabels = false,
  reverse,
  statuses,
  ...observables
}: ExecutionTimelineProps) {
  const intl = useIntl();
  const listRef = useRef<VariableSizeList<ExecutionTimelineRowProps> | null>(null);
  const [scrollListElement, setScrollListElement] = useState<HTMLDivElement | null>(null);
  const rowHeights = useRef<Record<number, number>>({});
  const openedGroups$ = useConstant(new BehaviorSubject<Set<number>>(new Set()));
  const { user } = useUserContext();

  const items = useObservableValue<ExecutionTimelineEntry<WithTimestamp>[]>(
    () =>
      // Merge all passed observables
      combineLatest(
        compact([
          observables.customerExecutionReports,
          observables.customerQuotes,
          observables.executionReports,
          observables.quotes,
          observables.loanQuotesBorrower,
          observables.loanQuotesLender,
          observables.loans,
          observables.loanTransactions,
          observables.customerTrades,
          observables.customerTransactions,
          observables.careExecutionReports,
        ]) as Observable<WithTimestamp[]>[]
      ).pipe(
        // Construct timeline item data + save the raw data
        map((values: WithTimestamp[][]) => {
          const rawDataList = values.flat();
          const timelineEntries: ExecutionTimelineItemEntry<WithTimestamp>[] = [];
          // Keep track of previous item that is comparable.
          let previousComparableItem: WithTimestamp | undefined;

          for (let i = 0; i < rawDataList.length; i++) {
            const rawData = rawDataList[i];

            const itemData = getTimelineItemData(rawData, intl, showInitiatorLabels, user, previousComparableItem);

            if (rawData instanceof ExecutionReport && EXEC_TYPE_ENUM_HOLDS_PAST_TRUTH[rawData.ExecType]) {
              previousComparableItem = rawData;
            }

            if (itemData && isWithTimestamp(itemData)) {
              timelineEntries.push({ props: itemData, rawData, type: 'item' });
            }
          }
          return timelineEntries;
        }),
        map(items => {
          // Sort using string comparison since we need to include nanoseconds
          const sorted: ExecutionTimelineItemEntry<WithTimestamp>[] = sortTimelineItems(items, reverse);
          if (groupSimilarItems) {
            return groupSimilarTimelineItems(sorted);
          }
          return sorted;
        }),
        combineLatestWith(openedGroups$),
        map(([sorted, openedGroups]) => flattenOpenedGroups(sorted, openedGroups))
      ),
    [
      observables.customerExecutionReports,
      observables.customerQuotes,
      observables.executionReports,
      observables.quotes,
      observables.loanQuotesBorrower,
      observables.loanQuotesLender,
      observables.loans,
      observables.loanTransactions,
      observables.customerTrades,
      observables.customerTransactions,
      observables.careExecutionReports,
      groupSimilarItems,
      reverse,
      openedGroups$,
      showInitiatorLabels,
      user,
      intl,
    ]
  );

  const { handleClickJson, jsonModal } = useJsonModal();

  // Reset opened groups when filtering has changed
  useEffect(() => {
    if (openedGroups$.value.size !== 0) {
      openedGroups$.next(new Set());
    }
  }, [statuses, openedGroups$]);

  const handleGroupExpand = useCallback(
    (index: number) => {
      openedGroups$.next(openedGroups$.value.add(index));
    },
    [openedGroups$]
  );

  // Auto-expand first group when there's just one
  if (items && items.length === 1 && items[0].type === 'group' && openedGroups$.value.size !== 1) {
    handleGroupExpand(0);
  }

  const setRowHeight = useDynamicCallback((index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  });

  const firstTimestamp = useMemo(
    () => (items && items.length > 0 && items[0].type === 'item' ? items[0].props.timestamp : undefined),
    [items]
  );
  const getRowHeight = useCallback((index: number) => rowHeights.current[index] || DEFAULT_ROW_HEIGHT, [rowHeights]);

  const itemData: ExecutionTimelineRowProps = useMemo(
    () => ({
      items: items ?? [],
      handleClickJson,
      firstTimestamp,
      handleGroupExpand,
      openedGroups: openedGroups$.value,
      setRowHeight,
    }),
    [firstTimestamp, handleClickJson, handleGroupExpand, items, openedGroups$.value, setRowHeight]
  );

  const scrollToBottom = useCallback(() => {
    listRef.current?.scrollToItem(items ? items.length - 1 : 0, 'end');
  }, [items]);

  if (items == null) {
    return <LoaderTalos />;
  }

  return (
    <>
      {statuses && statuses.length > 0 && !items.length && (
        <EmptyDataWrapper>No events found matching the filters.</EmptyDataWrapper>
      )}
      <ItemsWrapper>
        <AutoSizer disableWidth>
          {({ height }) => (
            <VariableSizeList
              outerRef={setScrollListElement}
              height={height ?? DEFAULT_ROW_HEIGHT}
              itemCount={items.length}
              itemSize={getRowHeight}
              itemData={itemData}
              ref={listRef}
              width="100%"
            >
              {ExecutionTimelineRow}
            </VariableSizeList>
          )}
        </AutoSizer>
        <TimelineScrollOverlay scrollListElement={scrollListElement} items={items} scrollToBottom={scrollToBottom} />
      </ItemsWrapper>
      {jsonModal}
    </>
  );
}

interface ExecutionTimelineRowProps {
  items: ExecutionTimelineEntry<WithTimestamp>[];
  setRowHeight: (index: number, size: number) => void;
  handleClickJson: (rawData: WithTimestamp) => void;
  firstTimestamp?: string;
  openedGroups: Set<number>;
  handleGroupExpand: (index: number) => void;
}

const ExecutionTimelineRow = memo(function ExecutionTimelineRow({
  index,
  style,
  data: { items, setRowHeight, handleClickJson, firstTimestamp, openedGroups, handleGroupExpand },
}: ListChildComponentProps<ExecutionTimelineRowProps>) {
  const entry = items[index];
  const rowRef = useRef<HTMLDivElement>(null);
  const initialExpanded = openedGroups.has(index);

  // Update rowHeight for every visible row
  useEffect(() => {
    if (rowRef.current) {
      setRowHeight(index, rowRef.current.clientHeight);
    }
  }, [index, setRowHeight, initialExpanded, entry]);

  if (!entry) {
    return null;
  }

  return (
    <div style={style}>
      <ItemWrapper ref={rowRef} className={index === items.length - 1 ? 'last-item' : ''}>
        <div>
          {entry.type === 'item' && (
            <ExecutionTimelineItem
              firstTimestamp={firstTimestamp}
              handleClickJson={handleClickJson}
              entry={entry}
              key={index}
            />
          )}

          {entry.type === 'group' && (
            <ExecutionTimelineGroup
              firstTimestamp={firstTimestamp}
              handleClickJson={handleClickJson}
              initialExpanded={initialExpanded}
              groupIndex={index}
              onExpand={handleGroupExpand}
              entry={entry}
              key={index}
            />
          )}
        </div>
      </ItemWrapper>
    </div>
  );
},
areEqual);

function ExecutionTimelineGroup({
  entry,
  firstTimestamp,
  handleClickJson,
  initialExpanded,
  groupIndex,
  onExpand,
}: {
  entry: ExecutionTimelineGroupEntry<WithTimestamp>;
  firstTimestamp?: string;
  handleClickJson(rawData: WithTimestamp): void;
  initialExpanded: boolean;
  groupIndex: number;
  onExpand(groupIndex: number): void;
}) {
  const handleOnExpand = useCallback(() => {
    onExpand(groupIndex);
  }, [onExpand, groupIndex]);

  return (
    <TimelineGroup
      label={
        <Text>
          <FormattedMessage
            {...messages.showNumHiddenTrades}
            values={{
              numHiddenTrades: entry.items.length,
              textColor: (numHiddenTrades: number) => <Text color="colorTextImportant">{numHiddenTrades}</Text>,
            }}
          />
        </Text>
      }
      icon={IconName.ArrowRightCircle}
      initialExpanded={initialExpanded}
      onExpand={handleOnExpand}
    >
      {entry.items.map((item, index) => (
        <ExecutionTimelineItem
          entry={item}
          firstTimestamp={firstTimestamp}
          handleClickJson={handleClickJson}
          key={index}
        />
      ))}
    </TimelineGroup>
  );
}

function ExecutionTimelineItem({
  entry,
  firstTimestamp,
  handleClickJson,
}: {
  entry: ExecutionTimelineItemEntry<WithTimestamp>;
  firstTimestamp?: string;
  handleClickJson(rawData: WithTimestamp): void;
}) {
  return (
    <TimelineItem
      timestamp={getTimestampText(firstTimestamp, entry.props.timestamp)}
      label={entry.props.label}
      content={entry.props.content}
      icon={entry.props.icon}
      variant={entry.props.variant}
      initiator={entry.props.initiator}
      actions={
        <Tooltip tooltip="View JSON">
          <IconButton icon={IconName.Braces} ghost={true} onClick={() => handleClickJson(entry.rawData)} />
        </Tooltip>
      }
    />
  );
}
