import { ACTION, Box, SearchSelect, Text, VStack, useDynamicCallback, type TabProps } from '@talos/kyoko';
import type { OMSView } from 'components/OMS/OMSView';
import { useEffect, useMemo } from 'react';
import { newOrderTab, rfqTab, salesOrderTab, salesRfqTab } from '../../../components/OMS/viewsWithTabs';
import { useRoleAuth } from '../../../hooks';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

const NONE = { id: '', label: 'None' };
export const DefaultOrderTab = () => {
  const { defaultOrderFormTab, setDefaultOrderFormTab, enableSalesWorkflow } = useTradingSettings();
  const { isAuthorized } = useRoleAuth();

  const availableTabs = useMemo(
    () =>
      [
        NONE,
        isAuthorized(ACTION.SUBMIT_ORDER) && newOrderTab,
        isAuthorized(ACTION.REQUEST_RFQ) && rfqTab,
        isAuthorized(ACTION.SUBMIT_ORDER) && enableSalesWorkflow && salesOrderTab,
        isAuthorized(ACTION.REQUEST_RFQ) && enableSalesWorkflow && salesRfqTab,
      ].filter(x => !!x),
    [isAuthorized, enableSalesWorkflow]
  );

  const selectedTab = useMemo(
    () => availableTabs.find((tab: any) => tab.id === defaultOrderFormTab),
    [availableTabs, defaultOrderFormTab]
  );

  useEffect(() => {
    if (!selectedTab) {
      // the only reason this can happen is because the previous defaultOrderFormTab is no longer available to the user
      setDefaultOrderFormTab('');
    }
  }, [selectedTab, setDefaultOrderFormTab]);

  const updateSelectedStrategy = useDynamicCallback((tab: false | TabProps | undefined) => {
    setDefaultOrderFormTab((tab as TabProps).id as OMSView);
  });

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color="colorTextImportant">Default order tab</Text>
      <Box ml="spacingLarge">
        <SearchSelect
          selection={selectedTab || availableTabs[0]}
          options={availableTabs}
          getLabel={(tab: any) => tab.label}
          onChange={updateSelectedStrategy}
          initialSortByLabel={false}
          style={{ width: '200px' }}
        />
      </Box>
    </VStack>
  );
};
