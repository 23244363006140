import { type HedgeControlStatusEnum, HEDGER_STATUS, useObservableValue, useSubscription } from '@talos/kyoko';
import { map } from 'rxjs/operators';
import { useFeatureFlag } from './useFeatureFlag';

export interface HedgerStatus {
  OrderHedgingStatus: HedgeControlStatusEnum;
  PositionHedgingStatus?: string;
  SessionID?: string;
  Timestamp?: string;
}

export function useHedgerStatus(): HedgerStatus | undefined {
  const { enableDynamicDeltaHedging } = useFeatureFlag();

  const { data: subscription } = useSubscription<HedgerStatus>(
    enableDynamicDeltaHedging
      ? {
          name: HEDGER_STATUS,
          tag: 'useHedgerStatus',
        }
      : null
  );

  return useObservableValue(() => subscription.pipe(map(json => json?.data.at(0))), [subscription]);
}
