import type { Order } from '@talos/kyoko';
import {
  EMPTY_OBJECT,
  FilterClauseType,
  IconName,
  PricingModeEnum,
  ProductTypeEnum,
  UnifiedLiquidityEnum,
  WarningSeverity,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  getTypedKeys,
  intlDefaultFormatter,
  isMultileg,
  prettyWarningSeverity,
  productTypeLabel,
  productTypeToString,
  useOrderStatusFilter,
  useSecuritiesContext,
  useUnifiedLiquidityContext,
  type BlotterTableClientLocalFilter,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
} from '@talos/kyoko';
import { useIDFilter } from 'hooks/filters/useIDFilter';
import { useMarketsFilter } from 'hooks/filters/useMarketsFilter';
import { useSidesFilter } from 'hooks/filters/useSidesFilter';
import { useStrategiesFilter } from 'hooks/filters/useStrategiesFilter';
import { useSubAccountsFilter } from 'hooks/filters/useSubAccountsFilter';
import { useUsersFilter } from 'hooks/filters/useUsersFilter';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { compact, isEqual, startCase, values } from 'lodash';
import type { useContextBlotterFilter } from 'providers/ContextBlotterFilterProvider/useContextBlotterFilter';
import { useSubAccounts } from 'providers/SubAccountsContext';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

export interface BlotterTableOrderFilter extends DateRangeFilter {
  /** These are the order status texts we are filtering by on the client side */
  _statuses?: string[];
  /** These are the order statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
  Users?: string[];
  SubAccounts?: string[];
  Markets?: string[];
  SelectedMarkets?: string[];
  TradedMarkets?: string[];
  Sides?: string[];
  Strategies?: string[];
  Symbols?: string[];
  Warnings?: string[];
  ProductTypes?: string[];
  UnifiedLiquidity?: UnifiedLiquidityEnum[];
  HideApiCalls?: boolean;
  OrderID?: string;
  RFQID?: string;
  ClOrdID?: string;
  ParentOrderID?: string;
  ParentRFQID?: string;
  MarketAccounts?: string[];
  ShowDDHOrders?: boolean;
  PricingMode?: PricingModeEnum[];
}

interface UseOrderFilterParams {
  initialFilter?: BlotterTableOrderFilter;
  permanentFilters?: BlotterTableOrderFilter;
  saveFilter: (newFilter: any) => void;
  includeStatusFilter: boolean;
  includeIDFilter: boolean;
  additionalFilterState?: ReturnType<typeof useContextBlotterFilter>['additionalFilterState'];
  alwaysShowDDHOrders?: boolean;
}

export const useOrderFilter = ({
  initialFilter,
  permanentFilters,
  saveFilter,
  includeStatusFilter,
  includeIDFilter,
  additionalFilterState,
  alwaysShowDDHOrders,
}: UseOrderFilterParams) => {
  const { subAccountsEnabled } = useSubAccounts();
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();
  const { enableCalendarSpreads, enableCFDs } = useFeatureFlag();
  const { unifiedLiquidityTokenBySymbol } = useUnifiedLiquidityContext();
  const isUnifiedLiquidityEnabled = !!unifiedLiquidityTokenBySymbol?.size;
  const [filter, setFilter] = useState(() => ({
    ...cleanupInitialFilterDateRange(initialFilter),
    ...permanentFilters,
  }));

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableOrderFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      Object.assign(newFilter, permanentFilters);
      if (!isEqual(priorFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, permanentFilters, saveFilter]
  );

  useMemo(() => {
    if (additionalFilterState && 'MarketAccounts' in additionalFilterState) {
      setFilter(filter => {
        return {
          ...filter,
          MarketAccounts: additionalFilterState.MarketAccounts ?? [],
        };
      });
    }
  }, [additionalFilterState]);

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<Order>>(
    row => {
      const data = row.data;
      if (!data) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Strategies', data, 'Strategy')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'UnifiedLiquidity', data, 'unifiedLiquidity')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'PricingMode', data, 'PricingMode')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Warnings', data, 'warningSeverity')) {
        return false;
      }
      if (!alwaysShowDDHOrders && !filter.ShowDDHOrders && data.isDDHHedgeOrder) {
        return false;
      }
      if (
        filter.Users &&
        filter.Users.length > 0 &&
        !(
          (data.User && filter.Users.includes(data.User)) ||
          (data.RequestUser && filter.Users.includes(data.RequestUser))
        )
      ) {
        return false;
      }
      if (
        filter.SubAccounts &&
        filter.SubAccounts.length > 0 &&
        !(
          (data.SubAccount && filter.SubAccounts.includes(data.SubAccount)) ||
          data.Allocation?.Allocations.some(alloc => filter.SubAccounts?.includes(alloc.SubAccount))
        )
      ) {
        return false;
      }

      if (filter.ProductTypes) {
        const orderSecurity = securitiesBySymbol.get(data.Symbol);
        const isOrderMultileg = isMultileg(orderSecurity);
        // If filtering but product type falsy, return no match
        if (!orderSecurity?.ProductType) {
          return false;
        }
        let isMatch = false;
        for (const filterProductType of filter.ProductTypes) {
          switch (filterProductType) {
            // https://app.shortcut.com/talostrading/story/40197/
            // Product types should filter by value, but 'multi' is inferred from other fields.
            case 'Multi': {
              if (isOrderMultileg) {
                isMatch = true;
              }
              break;
            }
            default: {
              const stringProductType = productTypeToString(orderSecurity.ProductType);
              // If the security is multileg, we should only match it with 'Multi' above.
              if (!isOrderMultileg && filter.ProductTypes.includes(stringProductType)) {
                isMatch = true;
              }
            }
          }
          // Exit loop if we found match
          if (isMatch) {
            break;
          }
        }
        // If no match found, return false
        if (!isMatch) {
          return isMatch;
        }
      }

      // TODO filledMarkets vs Markets product question
      if (
        filter.Markets &&
        filter.Markets.length > 0 &&
        !(
          data.selectedAndTradedMarkets &&
          data.selectedAndTradedMarkets.some(orderMarket => filter.Markets?.some(market => orderMarket === market))
        )
      ) {
        return false;
      }

      if (
        filter.SelectedMarkets &&
        filter.SelectedMarkets.length > 0 &&
        !(
          data.selectedMarkets &&
          data.selectedMarkets.some(orderMarket => filter.SelectedMarkets?.some(market => orderMarket === market))
        )
      ) {
        return false;
      }

      if (
        filter.TradedMarkets &&
        filter.TradedMarkets.length > 0 &&
        !(
          data.tradedMarkets &&
          data.tradedMarkets.some(orderMarket => filter.TradedMarkets?.some(market => orderMarket === market))
        )
      ) {
        return false;
      }

      const filterMarketAccounts = filter.MarketAccounts;
      if (
        filterMarketAccounts &&
        filterMarketAccounts.length > 0 &&
        !(
          data.selectedMarketAccounts &&
          data.selectedMarketAccounts.some(orderMarketAccount =>
            filterMarketAccounts.some(marketAccount => orderMarketAccount === marketAccount)
          )
        )
      ) {
        return false;
      }

      return true;
    },
    [filter, securitiesBySymbol, alwaysShowDDHOrders]
  );

  const marketsFilter = useMarketsFilter({ onlyTradingMarkets: true });
  const subAccountsFilter = useSubAccountsFilter(EMPTY_OBJECT);
  const usersFilter = useUsersFilter();
  const orderStatusFilter = useOrderStatusFilter();
  const strategiesFilter = useStrategiesFilter();
  const sidesFilter = useSidesFilter();
  const idFilter = useIDFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      compact([
        {
          key: 'Symbols',
          label: 'Symbol',
          icon: IconName.CurrencyDollar,
          options: securitiesList.map(sec => sec.Symbol),
          getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || '',
        },
        subAccountsEnabled ? subAccountsFilter : null,
        marketsFilter,
        { ...marketsFilter, key: 'SelectedMarkets', label: 'Selected Markets' },
        { ...marketsFilter, key: 'TradedMarkets', label: 'Traded Markets' },
        includeStatusFilter ? orderStatusFilter : null,
        sidesFilter,
        strategiesFilter,
        usersFilter,
        // TODO: Get proper types on WarningSeverity
        {
          key: 'Warnings',
          label: 'Warning',
          icon: IconName.Exclamation,
          options: [WarningSeverity.LOW, WarningSeverity.MEDIUM, WarningSeverity.HIGH] as any[],
          getOptionLabel: (option: unknown) => prettyWarningSeverity(option as WarningSeverity, intlDefaultFormatter),
          optionSorter: (a: unknown, b: unknown) => ((b as WarningSeverity) || 0) - ((a as WarningSeverity) || 0),
        },
        {
          key: 'ProductTypes',
          label: 'Product',
          icon: IconName.Cube,
          options: compact([
            productTypeToString(ProductTypeEnum.Spot),
            productTypeToString(ProductTypeEnum.Future),
            productTypeToString(ProductTypeEnum.PerpetualSwap),
            productTypeToString(ProductTypeEnum.Option),
            enableCalendarSpreads ? productTypeToString(ProductTypeEnum.CalendarSpread) : null,
            enableCFDs ? productTypeToString(ProductTypeEnum.CFD) : null,
            'Multi',
          ]),
          getOptionLabel: productType => productTypeLabel(productType as ProductTypeEnum),
        },
        isUnifiedLiquidityEnabled
          ? {
              key: 'UnifiedLiquidity',
              label: 'Unified Liquidity',
              icon: IconName.Scale,
              options: [UnifiedLiquidityEnum.Enabled, UnifiedLiquidityEnum.Disabled],
              getOptionLabel: unifiedLiquidity => unifiedLiquidity,
            }
          : null,
        {
          key: 'PricingMode',
          label: 'Pricing Mode',
          icon: IconName.CurrencyDollar,
          options: values(PricingModeEnum),
          getOptionLabel: startCase,
        },
        includeIDFilter ? { ...idFilter, key: 'OrderID', label: 'Order ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'RFQID', label: 'RFQ ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'ClOrdID', label: 'ClOrdID' } : null,
        includeIDFilter ? { ...idFilter, key: 'ParentOrderID', label: 'Parent Order ID' } : null,
        includeIDFilter ? { ...idFilter, key: 'ParentRFQID', label: 'Parent RFQ ID' } : null,
      ]),
    [
      securitiesList,
      subAccountsEnabled,
      subAccountsFilter,
      marketsFilter,
      includeStatusFilter,
      orderStatusFilter,
      strategiesFilter,
      usersFilter,
      enableCalendarSpreads,
      enableCFDs,
      isUnifiedLiquidityEnabled,
      securitiesBySymbol,
      sidesFilter,
      idFilter,
      includeIDFilter,
    ]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      getTypedKeys(filter).forEach(key => {
        if (key === '_start' || key === 'StartDate' || key === 'TimestampField') {
          return;
        }
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: filter[key as keyof typeof filter] as string[],
        });
      });
    }
    return clauses;
  }, [filter]);

  return {
    filter,
    filterableProperties,
    initialFilterClauses,
    changeFilter,
    clientSideFilter,
  };
};

// Map from a ag-grid columnID to a filterbuilder key
// Returns undefined if the column can not be mapped to a filter builder clause
export function colIDToFilterBuilderKey(id: string): keyof BlotterTableOrderFilter | undefined {
  switch (id as keyof Order) {
    case 'Symbol':
      return 'Symbols';
    case 'SubAccount':
      return 'SubAccounts';
    case 'selectedAndTradedMarkets':
    case 'selectedAndTradedMarketAccounts':
    case 'Markets':
      return 'Markets';
    case 'selectedMarkets':
    case 'selectedMarketAccounts':
      return 'SelectedMarkets';
    case 'tradedMarkets':
    case 'tradedMarketAccounts':
      return 'TradedMarkets';
    case 'Side':
      return 'Sides';
    case 'OrdStatus':
      return '_statuses';
    case 'Strategy':
      return 'Strategies';
    case 'User':
      return 'Users';
    case 'warning':
      return 'Warnings';
    case 'unifiedLiquidity':
      return 'UnifiedLiquidity';
    case 'OrderID':
      return 'OrderID';
    case 'RFQID':
      return 'RFQID';
    case 'ClOrdID':
      return 'ClOrdID';
    case 'ParentOrderID':
      return 'ParentOrderID';
    case 'ParentRFQID':
      return 'ParentRFQID';
    case 'PricingMode':
      return 'PricingMode';
  }
  // Product type is coming from securities[order.symbol].
  if (id === 'productType') {
    return 'ProductTypes';
  }
  return undefined;
}
