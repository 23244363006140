import {
  BlotterTableContext,
  useCurrenciesContext,
  useExposureDefinitions,
  useIntl,
  useMarketAccountsContext,
  useMarketsContext,
  useObservableValue,
  useSecuritiesContext,
  useStrategiesContext,
  useUserContext,
  type BlotterTableContextProps,
} from '@talos/kyoko';
import { useAggregations } from 'hooks/useAggregations';
import { useMemo, type PropsWithChildren } from 'react';
import { useCustomersByName, useCustomersContext } from '../hooks/useCustomer';
import { useSubAccounts } from './SubAccountsContext';
import { useUsersContext } from './UsersContext';

export function BlotterTableContextProvider({ children }: PropsWithChildren) {
  const { users, userByID } = useUsersContext();
  const { user: currentUser } = useUserContext();
  const intl = useIntl();

  const { strategiesByName } = useStrategiesContext();

  const { currenciesBySymbol } = useCurrenciesContext();
  const { securitiesBySymbol } = useSecuritiesContext();
  const { marketDisplayNameByName, marketTypeByName } = useMarketsContext();
  const { customers: customersObs } = useCustomersContext();
  const customers = useObservableValue(() => customersObs, [customersObs], []);
  const customersByName = useCustomersByName();
  const { marketAccountsByName, marketAccountsByID } = useMarketAccountsContext();
  const { subAccountsByName, allSubAccounts, subAccountsByID } = useSubAccounts();
  const { exposureDefinitionsByName } = useExposureDefinitions();
  const { aggregationsByName } = useAggregations();
  const { counterparties, counterpartiesByName } = useUserContext();

  const context = useMemo(
    () =>
      ({
        currentUser,
        users,
        userByID,
        marketDisplayNameByName,
        marketTypeByName,
        strategiesByName,
        currenciesBySymbol,
        securitiesBySymbol,
        customers,
        customersByName,
        counterparties,
        counterpartiesByName,
        marketAccountsByName,
        marketAccountsByID,
        subAccountsByName,
        subAccountsByID,
        allSubAccounts,
        exposureDefinitionsByName,
        aggregationsByName,
        intl,
      } satisfies BlotterTableContextProps),
    [
      currentUser,
      users,
      userByID,
      marketDisplayNameByName,
      marketTypeByName,
      strategiesByName,
      currenciesBySymbol,
      securitiesBySymbol,
      customers,
      customersByName,
      counterparties,
      counterpartiesByName,
      marketAccountsByName,
      marketAccountsByID,
      subAccountsByName,
      subAccountsByID,
      allSubAccounts,
      exposureDefinitionsByName,
      aggregationsByName,
      intl,
    ]
  );

  return <BlotterTableContext.Provider value={context}>{children}</BlotterTableContext.Provider>;
}
