import { FormGroup, HStack, Input, MAX_BPS_OFFSET, MIN_BPS_OFFSET, Text, VStack } from '@talos/kyoko';
import { useCallback, useState } from 'react';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';

export function DefaultPriceOffsets() {
  const { defaultPriceOffsets, setDefaultPriceOffsets } = useTradingSettings();
  const [priceOffsets, setPriceOffsets] = useState<string[]>(defaultPriceOffsets.split('|'));
  const [error, setError] = useState<string>('');

  const handleOnChange = useCallback(
    (index: number, value: string) => {
      const newPriceOffsets = [...priceOffsets];
      newPriceOffsets[index] = value;
      setPriceOffsets(newPriceOffsets);

      const offsetA = parseInt(newPriceOffsets[0]);
      const offsetB = parseInt(newPriceOffsets[1]);
      let newError = '';
      if (!newPriceOffsets[0] || !newPriceOffsets[1] || offsetA < MIN_BPS_OFFSET || offsetB < MIN_BPS_OFFSET) {
        newError = `Both numbers must be greater or equal ${MIN_BPS_OFFSET}.`;
      } else if (offsetA > offsetB) {
        newError = 'The second number must be larger than the first.';
      } else if (offsetA === offsetB) {
        newError = 'Both numbers can’t be the same.';
      } else if (offsetA >= MAX_BPS_OFFSET || offsetB >= MAX_BPS_OFFSET) {
        newError = `Both numbers must be less than ${MAX_BPS_OFFSET}.`;
      }
      setError(newError);
      if (!newError) {
        setDefaultPriceOffsets([offsetA, offsetB].join('|'));
      }
    },
    [priceOffsets, setDefaultPriceOffsets, setPriceOffsets, setError]
  );

  return (
    <VStack gap="spacingDefault" alignItems="flex-start">
      <Text color="colorTextImportant">Limit Px offsets</Text>
      <VStack gap="spacingDefault" ml="spacingLarge" alignItems="flex-start">
        <span>
          Set custom price offset shortcuts that appear below the limit px field in the New Order and Sales Order forms.
        </span>
        <FormGroup error={error} w="100%">
          <HStack gap="spacingDefault" justifyContent="flex-start">
            <Input
              width="96px"
              autoComplete="off"
              inputType="number"
              value={priceOffsets[0]}
              onChange={e => handleOnChange(0, e.target.value)}
              suffix="BPS"
            />
            <Input
              width="96px"
              autoComplete="off"
              inputType="number"
              value={priceOffsets[1]}
              onChange={e => handleOnChange(1, e.target.value)}
              suffix="BPS"
            />
          </HStack>
        </FormGroup>
      </VStack>
    </VStack>
  );
}
