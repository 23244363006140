import type { LedgerAccountTypeEnum, MarketAccount, MarketDataCardConfig, RangeSelectObject } from '@talos/kyoko';
import type { ResolutionString } from '@trading-view/charting_library';
import type { OrderDetailsPath } from 'containers/Trading/Markets/OrderDetails/types';

import { DEFAULT_COLUMNS } from 'tokens/appconfig';
import type { MultilegComboType } from '../components/MultilegCombo/enums';
import type { PriceBlotterTabProps } from '../containers/Blotters/PriceBlotter/types';

export type OptionFilter = {
  market: string;
  currency: string;
  underlyingCode?: string;
  expiry: string | null;
  marketAccountName: MarketAccount['Name'] | null;
};

export type StrikeFilter = {
  strikePrice?: RangeSelectObject;
  delta?: RangeSelectObject;
  atm?: number;
};
export interface CurrentMarketTabDefaults {
  defaultSubAccountId: number | undefined;
}

export enum MarketTabType {
  Market = 'Market',
  DeepDive = 'DeepDive',
  OrderDetails = 'OrderDetails',
  Option = 'Option',
  Multileg = 'Multileg',
  ReconDetails = 'ReconDetails',
  AccountLedgerEventsDetails = 'AccountLedgerEventsDetails',
  PriceBlotter = 'Price Grid',
  MultilegPriceBlotter = 'Multileg Price Grid',
  PositionAutoHedging = 'PAH Rules',
  CareOrders = 'Care Orders',
}

export interface IMarketTab {
  id: string;
  label: string;
  isTemporary?: boolean;
  // temporary tabs can now be save-able or not
  canSave?: boolean;
}

export interface GeneralMarketTab extends IMarketTab {
  type: MarketTabType.Market;
  columns?: MarketDataCardConfig[][];
  defaults?: Partial<CurrentMarketTabDefaults>;
}

export type DeepDiveMarketTabData = {
  selectedMarkets: string[];
  selectedMarketAccounts: string[];
  priceIncrement?: string;
  showMarkets?: boolean;
  showCumQty?: boolean;
  chartResolution?: ResolutionString;
};

export interface DeepDiveMarketTab extends IMarketTab {
  type: MarketTabType.DeepDive;
  symbol: string;
  data: DeepDiveMarketTabData;
}

export interface PositionAutoHedgingTab extends IMarketTab {
  type: MarketTabType.PositionAutoHedging;
}

export interface ReconDetailsMarketTab extends IMarketTab {
  type: MarketTabType.ReconDetails;
  mismatchIDs: string[];
}

export interface AccountLedgerEventsDetailsMarketTab extends IMarketTab {
  type: MarketTabType.AccountLedgerEventsDetails;
  account: string;
  accountType: LedgerAccountTypeEnum;
  asset?: string;
}

export interface OrderDetailsMarketTab extends IMarketTab {
  type: MarketTabType.OrderDetails;
  orderID: string;
  openTab: OrderDetailsPath;
}

export interface CareOrdersTabData {
  // TODO fhqvst We're not entirely sure what will live here yet, so leaving empty
}

export interface CareOrdersTab extends IMarketTab {
  type: MarketTabType.CareOrders;
  data: CareOrdersTabData;
}

export function isOptionMarketTab(tab: MarketTab): tab is OptionMarketTab {
  return tab.type === MarketTabType.Option;
}

export function isPositionAutoHedgingTab(tab: MarketTab): tab is PositionAutoHedgingTab {
  return tab.type === MarketTabType.PositionAutoHedging;
}

export function isReconDetailsMarketTab(tab: MarketTab): tab is ReconDetailsMarketTab {
  return tab.type === MarketTabType.ReconDetails;
}

export function isAccountLedgerEventsDetailsMarketTab(tab: MarketTab): tab is AccountLedgerEventsDetailsMarketTab {
  return tab.type === MarketTabType.AccountLedgerEventsDetails;
}

export function isMultilegMarketTab(tab: MarketTab): tab is MultilegMarketTab {
  return tab.type === MarketTabType.Multileg;
}

export function isPriceBlotterMarketTab(tab: MarketTab): tab is PriceBlotterMarketTab {
  return tab.type === MarketTabType.PriceBlotter;
}

export function isCareOrdersTab(tab: MarketTab): tab is CareOrdersTab {
  return tab.type === MarketTabType.CareOrders;
}

export type OptionMarketTabData = {
  optionFilter: OptionFilter;
  strikeFilter: StrikeFilter;
  columnDefsVisibility?: Record<string, boolean>;
};

export interface OptionMarketTab extends IMarketTab {
  type: MarketTabType.Option;
  data?: OptionMarketTabData;
}

export type MultilegTabData = {
  instrumentType: MultilegComboType;
  resolvedSymbol?: string;
  initiatingLegs?: boolean[];
  compactOrderBook?: boolean;
};

export interface MultilegMarketTab extends IMarketTab {
  type: MarketTabType.Multileg;
  data: MultilegTabData;
}

type PriceBlotterTabData = Partial<
  Pick<PriceBlotterTabProps, 'defaultColumns' | 'defaultFilter' | 'defaultRowGroupsOpened'>
>;
export interface PriceBlotterMarketTab extends IMarketTab {
  type: MarketTabType.PriceBlotter;
  data?: PriceBlotterTabData;
}

export type MarketTab =
  | GeneralMarketTab
  | DeepDiveMarketTab
  | OrderDetailsMarketTab
  | OptionMarketTab
  | MultilegMarketTab
  | ReconDetailsMarketTab
  | AccountLedgerEventsDetailsMarketTab
  | PriceBlotterMarketTab
  | PositionAutoHedgingTab
  | CareOrdersTab;

export const DefaultMarketTabsConfig = {
  marketTabs: [{ columns: DEFAULT_COLUMNS, label: 'Market' }] as MarketTab[],
  sortCards: false,
};

export interface UseInitialMarketTabsStateProps {
  initialMarketTabs: MarketTab[];
  setMarketTabs: (marketTabs: MarketTab[]) => void;
  enableFlexibleLayout: boolean;
}
