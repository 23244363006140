import {
  AccordionGroup,
  Button,
  ButtonVariants,
  EXPANDABLE_MIN_HEIGHT,
  FormControlSizes,
  LoaderTalos,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  ToggleAccordionsButton,
  VStack,
} from '@talos/kyoko';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ExpandableBottomPanel, ExpandablePanelHeader } from 'components/ExpandableBottomPanel';
import { TransfersBlotter } from 'containers/Blotters/Transfers';
import { useCallback, useRef, useState } from 'react';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import { BalancesV2 } from '../../Blotters/BalancesV2';
import { DealerSettlementAccordionTable } from './DealerSettlementAccordionTable';
import { RelatedTransfersWidget } from './RelatedTransfers/RelatedTransfersWidget';
import { LoaderContainer } from './styles';
import { useRelativeMarketPositions } from './useRelativeMarketPositions';

const TABS = [
  {
    label: 'Transfers',
    render: p => <TransfersBlotter {...p} />,
  },
  {
    label: 'Balances',
    render: p => <BalancesV2 {...p} />,
  },
];

const TABS_LABELS = TABS.map(tab => tab.label);

export const DealerSettlement = () => {
  const marketPositions = useRelativeMarketPositions();
  const { otcSettlementShowAll, setOTCSettlementShowAll } = useDisplaySettings();

  // blotters / expandable panel state
  const isBlotterDragged = useRef(false);
  const [blotterHeight, setBlotterHeight] = useState(300);
  const [activeBlotterPanelIndex, setActiveBlotterPanelIndex] = useState(0);

  const handleShowAllClick = useCallback(() => {
    setOTCSettlementShowAll(!otcSettlementShowAll);
  }, [setOTCSettlementShowAll, otcSettlementShowAll]);

  const onTabSelected = index => {
    setActiveBlotterPanelIndex(index);
  };

  return (
    <AccordionGroup>
      <Panel style={{ position: 'relative' }}>
        <PanelHeader>
          <h2>OTC Settlement</h2>
          <PanelActions>
            {marketPositions && <RelatedTransfersWidget marketPositions={marketPositions} />}
            <Button variant={ButtonVariants.Default} size={FormControlSizes.Default} onClick={handleShowAllClick}>
              {otcSettlementShowAll ? 'Hide unsettleable' : 'Show all'}
            </Button>
            <ToggleAccordionsButton variant={ButtonVariants.Default} size={FormControlSizes.Default} />
          </PanelActions>
        </PanelHeader>
        <VStack gap="spacingTiny" h="100%" w="100%" overflow="hidden">
          <PanelContent>
            {marketPositions == null ? (
              <LoaderContainer>
                <LoaderTalos />
              </LoaderContainer>
            ) : (
              <DealerSettlementAccordionTable marketPositions={marketPositions} showAll={otcSettlementShowAll} />
            )}
          </PanelContent>
          <ExpandableBottomPanel
            height={blotterHeight}
            isDragged={isBlotterDragged}
            onAdjustedHeight={setBlotterHeight}
          >
            <ExpandablePanelHeader
              activePanelIndex={activeBlotterPanelIndex}
              onTabSelected={onTabSelected}
              children={null}
              tabs={TABS_LABELS}
              showControls={false}
              isMinimized={blotterHeight === EXPANDABLE_MIN_HEIGHT}
            />
            {activeBlotterPanelIndex >= 0 &&
              TABS.map(
                (tab, i) =>
                  activeBlotterPanelIndex === i && (
                    <ErrorBoundary key={`component-${i}`}>
                      {tab.render({
                        activeBlotterPanelIndex,
                        onSetActiveBlotterPanelIndex: setActiveBlotterPanelIndex,
                      })}
                    </ErrorBoundary>
                  )
              )}
          </ExpandableBottomPanel>
        </VStack>
      </Panel>
    </AccordionGroup>
  );
};
