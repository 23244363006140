import type { CareOrder, MinimalSubscriptionResponse } from '@talos/kyoko';
import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  Button,
  ButtonGroup,
  ButtonVariants,
  CurrencyIcon,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  Divider,
  EMPTY_ARRAY,
  FormControlSizes,
  HStack,
  IconButton,
  IconName,
  InlineFormattedNumber,
  MixpanelEvent,
  ToggleButton,
  baseTreeGroupColumnDef,
  createCSVFileName,
  getTreeRowBlotterGroupColDef,
  stringColumnComparatorMultiColumn,
  useAccordionFilterBuilder,
  useBlotterTable,
  useBlotterTableExtrasMenu,
  useDateRangeFilter,
  useDisclosure,
  useDynamicCallback,
  useMixpanel,
  useObservableValue,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  useSecuritiesContext,
  type BlotterTableFilter,
  type Column,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { ColDef, GridOptions, RowDoubleClickedEvent } from 'ag-grid-community';
import Big from 'big.js';
import { useDetailsDrawer } from 'components/DetailsDrawer/useDetailsDrawer';
import { newCareOrder } from 'components/OMS/CareOrderFormView/CareOrderSlice';
import { OMSView } from 'components/OMS/OMSView';
import { useAppStateDispatch } from 'providers/AppStateProvider';
import { useFixingIndices } from 'providers/FixingIndexProvider';
import { useMemo } from 'react';
import { map, type Observable } from 'rxjs';
import styled from 'styled-components';
import { openView } from '../../../components/OMS/OMSSlice';
import { CareOrderBlotterHUD } from './CareOrderBlotterHUD';
import { CareOrderImportDialog } from './CareOrderImport/CareOrderImportDialog';
import { isCareOrderRow, isQuoteRow, type CareOrderBlotterEntity } from './types';
import { useCareOrderBlotterDataObs } from './useCareOrderBlotterDataObs';
import { useCareOrderColumns } from './useCareOrderColumns';
import { useCareOrderFilter } from './useCareOrderFilter';
import { canceledCareOrderStatuses } from './useCareOrderHUDData';
import { useCareOrderMenu } from './useCareOrderMenu';

const careOrderBlotterEntityComparator = stringColumnComparatorMultiColumn<CareOrderBlotterEntity>(['Symbol']);

function getDataPath(row: CareOrderBlotterEntity): string[] {
  return row.dataPath;
}

export interface CareOrderBlotterParams {
  blotterID: string;
  portalId?: string;
  defaultFilter?: BlotterTableFilter;
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
  defaultRowGroupsOpened?: RowGroupsOpenedState;
  showHUD?: boolean;
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  && .ag-pinned-left-cols-container,
  && .ag-pinned-left-header {
    border-right: 1px solid var(--colors-gray-050);
  }
`;

export function CareOrderBlotter({
  blotterID,
  portalId,
  defaultColumns,
  defaultFilter,
  defaultRowGroupsOpened,
  showHUD,
}: CareOrderBlotterParams) {
  const dispatch = useAppStateDispatch();
  const mixpanel = useMixpanel();

  const defaultCareOrderColumns = useCareOrderColumns({ defaultColumns });

  const persistedBlotterTable = usePersistedBlotterTable<CareOrderBlotterEntity>(blotterID, {
    columns: defaultCareOrderColumns,
    filter: defaultFilter,
    sort: '+ag-Grid-AutoColumn',
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const careOrderMenu = useCareOrderMenu();

  const columnsWithMenu = useMemo(
    () => [...persistedBlotterTable.columns, careOrderMenu.column],
    [careOrderMenu.column, persistedBlotterTable.columns]
  );

  const handleColumnsChanged = useDynamicCallback((columns: Column[]) => {
    persistedBlotterTable.onColumnsChanged(columns);
  });

  const { openDetailsDrawer } = useDetailsDrawer();
  const handleDoubleClickRow = useDynamicCallback(({ data }: RowDoubleClickedEvent<CareOrderBlotterEntity>) => {
    if (isQuoteRow(data) || isCareOrderRow(data)) {
      openDetailsDrawer({ entity: data });
    }
  });

  const autoGroupColumnDef: ColDef = useMemo(
    () =>
      ({
        ...baseTreeGroupColumnDef,
        headerName: 'ID',
        pinned: 'left',
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        comparator: (valueA, valueB, nodeA, nodeB) => careOrderBlotterEntityComparator(nodeA.data, nodeB.data),
        width: 100,
        ...getTreeRowBlotterGroupColDef(),
      } satisfies GridOptions['autoGroupColumnDef']),
    []
  );

  const careOrderFilter = useCareOrderFilter({
    initialFilter: persistedBlotterTable.initialFilter,
    saveFilter: persistedBlotterTable.onFilterChanged,
  });
  const dateRangeFilter = useDateRangeFilter(careOrderFilter.filter, careOrderFilter.changeFilter);
  const dataObservable = useCareOrderBlotterDataObs();

  const { filterBuilderProps } = careOrderFilter;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useBlotterTable<CareOrderBlotterEntity>({
    dataObservable,
    rowID: 'rowID',
    sort: persistedBlotterTable.initialSort,
    onColumnsChanged: handleColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    clientLocalFilter: careOrderFilter.clientSideFilter,
    columns: columnsWithMenu,
    gridOptions: {
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      onRowDoubleClicked: handleDoubleClickRow,
      showOpenedGroup: true,
      autoGroupColumnDef,
      treeData: true,
      getDataPath,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems: careOrderMenu.getContextMenuItems,
      suppressAggFuncInHeader: true,
    },
  });

  const { expandAllGroups, collapseAllGroups } = blotterTable;

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const handleExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    blotterTable.exportDataAsCSV({
      skipRowGroups: false,
      fileName: createCSVFileName({
        name: 'Care Orders',
        tabLabel: 'Care Orders',
      }),
    });
    extrasMenuPopover.close();
  });

  const handleClickNewCareOrder = useDynamicCallback(() => {
    dispatch(newCareOrder());
    dispatch(openView(OMSView.CareOrderForm));
  });

  const importDialog = useDisclosure();
  const handleClickImport = useDynamicCallback(() => {
    importDialog.open();
  });

  const handleSymbolChanged = useDynamicCallback((symbols: string[]) => {
    careOrderFilter.changeFilter(curr => ({ ...curr, Symbols: symbols }));
  });

  return (
    <Wrapper>
      <BlotterTableFilters
        portalId={portalId}
        {...filterBuilderAccordion}
        accordionBodyPrefix={
          <SymbolFilterButtons
            dataObservable={dataObservable}
            onChange={handleSymbolChanged}
            value={careOrderFilter.filter.Symbols}
          />
        }
        {...blotterTable.blotterTableFiltersProps}
        {...dateRangeFilter}
        suffix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="expand-all-button"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
            />
            <Divider orientation="vertical" mx="spacingSmall" />
            <Button size={FormControlSizes.Small} startIcon={IconName.DocumentUpload} onClick={handleClickImport}>
              Import
            </Button>
            <Button
              size={FormControlSizes.Small}
              variant={ButtonVariants.Primary}
              startIcon={IconName.Plus}
              onClick={handleClickNewCareOrder}
            >
              New Care Order
            </Button>
            <BlotterTableExtrasMenu {...extrasMenuPopover}>
              <Button startIcon={IconName.DocumentDownload} size={FormControlSizes.Small} onClick={handleExport}>
                Export
              </Button>
            </BlotterTableExtrasMenu>
          </>
        }
      />
      {showHUD && <CareOrderBlotterHUD blotterTable={blotterTable} />}
      <BlotterTable {...blotterTable} />
      {careOrderMenu.dialogs}
      <CareOrderImportDialog {...importDialog} />
    </Wrapper>
  );
}

function SymbolFilterButtons({
  onChange,
  value = EMPTY_ARRAY,
  dataObservable,
}: {
  onChange: (symbols: string[]) => void;
  value?: string[];
  dataObservable: Observable<MinimalSubscriptionResponse<CareOrderBlotterEntity>>;
}) {
  const { fixingIndicesBySymbol } = useFixingIndices();
  const { securitiesBySymbol } = useSecuritiesContext();
  const symbols = [...fixingIndicesBySymbol.keys()];

  const quantityBySymbol = useObservableValue(
    () =>
      dataObservable.pipe(
        map(({ data }) => {
          const quantityBySymbol = new Map<string, Big>();
          for (const row of data) {
            if (!isCareOrderRow(row)) {
              continue;
            }
            const symbol = row.Symbol;
            const quantity = canceledCareOrderStatuses.has(row.status) ? row.CumQty : row.OrderQty;
            const currentQuantity = quantityBySymbol.get(symbol) ?? Big(0);

            quantityBySymbol.set(symbol, currentQuantity.plus(quantity));
          }
          return quantityBySymbol;
        })
      ),
    [dataObservable],
    new Map<string, Big>()
  );

  return (
    <ButtonGroup>
      {symbols.map(symbol => {
        const security = securitiesBySymbol.get(symbol);
        if (security == null) {
          return null;
        }
        return (
          <ToggleButton
            key={security.Symbol}
            size={FormControlSizes.Small}
            selected={value.includes(symbol)}
            onClick={() => onChange(value.includes(symbol) ? value.filter(s => s !== symbol) : [...value, symbol])}
          >
            <HStack gap="spacing6">
              <CurrencyIcon currency={security.BaseCurrency} colorful={true} />
              <InlineFormattedNumber
                number={quantityBySymbol.get(symbol) ?? 0}
                increment={security.DefaultSizeIncrement}
                round={true}
              />
              {security.BaseCurrency}
            </HStack>
          </ToggleButton>
        );
      })}
    </ButtonGroup>
  );
}
