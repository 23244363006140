import {
  BlotterTable,
  BlotterTableExtrasMenu,
  BlotterTableFilters,
  BlotterTableOverlay,
  Button,
  ButtonVariants,
  CUSTOMER_BALANCE,
  CUSTOM_BLOTTER_GROUPING,
  CustomerLedgerUpdateTypeEnum,
  DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
  FormControlSizes,
  IconButton,
  IconName,
  InlineFormattedNumberContext,
  MixpanelEvent,
  MixpanelEventProperty,
  Toggle,
  columnTypes,
  createCSVFileName,
  filterByCellValueMenuItem,
  isGridApiReady,
  useAccordionFilterBuilder,
  useAggDeltaUpdatesPipe,
  useBlotterGroupingToggling,
  useBlotterTableExtrasMenu,
  useConstant,
  useDynamicCallback,
  useGetDefaultContextMenuItems,
  useJsonModal,
  useMixpanel,
  usePersistedBlotterTable,
  usePersistedRowGroupsOpenedState,
  useWSFilterPipe,
  useWsBlotterTable,
  type BlotterGroupingDefinition,
  type Column,
  type CustomerBalance,
  type Leaves,
  type RowGroupsOpenedState,
} from '@talos/kyoko';
import type { GetContextMenuItemsParams, RowDoubleClickedEvent, SelectionChangedEvent } from 'ag-grid-community';
import { compact, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFeatureFlag } from '../../../hooks';
import { useDisplaySettings } from '../../../providers/DisplaySettingsProvider';
import { BalancesBlotterSummaryLine } from '../../Blotters/BalancesV2/SummaryLine/BalancesBlotterSummaryLine';
import {
  defaultCustomerBalancesColumns,
  defaultCustomerBalancesFilter,
  defaultCustomerBalancesRowGroupsOpened,
} from './defaults';
import type { CustomerBalancesFilter, CustomerBalancesTabsProps } from './types';
import { useCustomerBalancesColumns } from './useCustomerBalancesColumns';
import { colIDToFilterBuilderKey, useCustomerBalancesFilter } from './useCustomerBalancesFilter';

function getCustomerBalanceKey(cb: CustomerBalance) {
  return cb.rowID;
}

const AGG_SPECS = [{ valuePath: 'Equivalent.Amount', currencyPath: 'Equivalent.Currency' }] satisfies {
  valuePath: Leaves<CustomerBalance>;
  currencyPath: Leaves<CustomerBalance>;
}[];

type Groupings = 'Customer' | 'Asset' | typeof CUSTOM_BLOTTER_GROUPING;
const GROUPINGS: BlotterGroupingDefinition<Groupings, keyof CustomerBalance>[] = [
  {
    key: 'Customer',
    label: 'By Customer',
    rowGroupColumns: ['Counterparty'],
    mixpanelEvent: MixpanelEvent.GroupCustomer,
    buttonDataTestId: 'by-customer-button',
  },
  {
    key: 'Asset',
    label: 'By Asset',
    rowGroupColumns: ['Currency'],
    mixpanelEvent: MixpanelEvent.GroupAssets,
    buttonDataTestId: 'by-asset-button',
  },
  {
    key: CUSTOM_BLOTTER_GROUPING,
    label: 'Custom',
    rowGroupColumns: [],
  },
];

interface CustomerBalancesBlotterProps {
  blotterID: string;
  tab: CustomerBalancesTabsProps;
  onUpdateTab: (tab: CustomerBalancesTabsProps) => void;
  initialFiltersOpen?: boolean;
  onCloneTab: (
    filter: CustomerBalancesFilter,
    columns: Column[],
    rowGroupsOpenedState: RowGroupsOpenedState | undefined,
    showZeroBalances: boolean
  ) => void;
  onRowDoubleClicked: (event: RowDoubleClickedEvent<CustomerBalance>) => void;
  onTransactionPrime: (customerBalance: CustomerBalance, transactionType: CustomerLedgerUpdateTypeEnum) => void;
}

export const CustomerBalancesBlotter = ({
  blotterID,
  tab,
  initialFiltersOpen,
  onCloneTab,
  onRowDoubleClicked,
  onTransactionPrime,
  onUpdateTab,
}: CustomerBalancesBlotterProps) => {
  const { requireCustomerBalancesCounterpartyFilter } = useFeatureFlag();
  const mixpanel = useMixpanel();
  const [selectedRows, setSelectedRows] = useState<CustomerBalance[]>([]);
  const { homeCurrency } = useDisplaySettings();

  const defaultBlotterColumns = tab.defaultColumns ?? defaultCustomerBalancesColumns;
  const defaultFilter = tab.defaultFilter ?? defaultCustomerBalancesFilter;
  const defaultRowGroupsOpened = tab.defaultRowGroupsOpened ?? defaultCustomerBalancesRowGroupsOpened;
  const showZeroBalances = tab.showZeroBalances ?? false;
  const showAllDecimals = tab.showAllDecimals ?? false;

  const defaultColumns = useCustomerBalancesColumns({
    defaultColumns: defaultBlotterColumns,
    onTransactionPrime,
  });
  const persistedBlotterTable = usePersistedBlotterTable<CustomerBalance>(blotterID, {
    columns: defaultColumns,
    filter: defaultFilter,
  });

  const persistedRowGroupsOpened = usePersistedRowGroupsOpenedState(blotterID, {
    defaultRowGroupsOpened,
  });

  const filterResults = useCustomerBalancesFilter({
    persistedBlotterTable,
  });

  const { filterBuilderProps } = filterResults;

  const handleDisplayedColumnsChanged = useDynamicCallback((columns: Column<any>[]) => {
    updateGroupingOnColumnsChanged?.(columns);
    persistedBlotterTable.onColumnsChanged(columns);

    mixpanel.track(MixpanelEvent.ChangeBlotterColumns, {
      [MixpanelEventProperty.TabLabel]: 'Customer Transfers',
    });
  });

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: initialFiltersOpen },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const rxjsFilterFunc = useCallback(
    (data: CustomerBalance) => {
      if (!showZeroBalances && data.Amount === '0') {
        return false;
      }

      return true;
    },
    [showZeroBalances]
  );
  const filterPipe = useWSFilterPipe({ getUniqueKey: getCustomerBalanceKey, filterFunc: rxjsFilterFunc });

  const { handleClickJson, jsonModal } = useJsonModal();
  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<CustomerBalance>) => {
      const data = params.node?.data;

      const items = compact([
        ...filterByCellValueMenuItem({
          params,
          openClause: filterBuilderAccordion.openClause,
          filterableProperties: filterBuilderProps.properties,
          colIDToFilterBuilderKey,
          mixpanel,
        }),
        'separator',
        data
          ? {
              action: () => handleClickJson(data),
              name: 'Show JSON',
              icon: `<i class="ag-icon ${IconName.Braces}"/>`,
            }
          : undefined,
        'separator',
        ...getDefaultContextMenuItems(params),
      ]);

      if (data != null) {
        items.unshift('separator');
        items.unshift({
          action: () => onTransactionPrime(data, CustomerLedgerUpdateTypeEnum.Withdraw),
          name: 'Create Withdrawal',
          icon: `<i class="ag-icon ${IconName.Upload}"/>`,
        });
        items.unshift({
          action: () => onTransactionPrime(data, CustomerLedgerUpdateTypeEnum.Deposit),
          name: 'Create Deposit',
          icon: `<i class="ag-icon ${IconName.Download}"/>`,
        });
      }

      return items;
    },
    [
      filterBuilderAccordion.openClause,
      filterBuilderProps.properties,
      mixpanel,
      handleClickJson,
      getDefaultContextMenuItems,
      onTransactionPrime,
    ]
  );

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        editable: false,
        hide: false,
        suppressColumnsToolPanel: false,
        params: {
          suppressCount: true,
        },
        sortable: true,
      }),
    []
  );

  const handleRowSelectionChanged = useCallback(({ api }: SelectionChangedEvent<CustomerBalance>) => {
    if (!isGridApiReady(api)) {
      return;
    }
    const rows = api.getSelectedRows();
    setSelectedRows(compact(rows));
  }, []);

  const aggPipe = useAggDeltaUpdatesPipe({
    getUniqueKey: getCustomerBalanceKey,
    aggSpecs: AGG_SPECS,
  });

  const request = useMemo(
    () => ({
      name: CUSTOMER_BALANCE,
      tag: blotterID,
      EquivalentCurrency: homeCurrency,
      ShowZeroBalances: true, // we always send ShowZeroBalances true to the backend, but we have an additional filtering on the frontend
    }),
    [blotterID, homeCurrency]
  );

  const initialRequest = useConstant(request);

  const hasRequiredFilterSelections = useMemo(() => {
    if (!requireCustomerBalancesCounterpartyFilter) {
      return true;
    }
    // For now we only care about this one filter clause. In the future, we'll probably extend this to an OR operation
    // across several properties (eg including Symbol here)
    const clause = filterBuilderAccordion.filterBuilder.filterClausesByPropertyKey.get(
      'Counterparties' satisfies keyof CustomerBalancesFilter
    );
    return clause != null && clause.selections.length > 0;
  }, [requireCustomerBalancesCounterpartyFilter, filterBuilderAccordion.filterBuilder.filterClausesByPropertyKey]);

  const blotterTable = useWsBlotterTable({
    initialRequest,
    filter: onlyServerKeys(filterResults.filter),
    allowRequest: hasRequiredFilterSelections,
    initialSort: persistedBlotterTable.initialSort,
    pipe: filterPipe,
    pinnedRowDataPipe: aggPipe,
    rowID: 'rowID' satisfies keyof CustomerBalance,
    columns: persistedBlotterTable.columns,
    handleClickJson,
    onSortChanged: persistedBlotterTable.onSortChanged,
    onColumnsChanged: handleDisplayedColumnsChanged,
    gridOptions: {
      onSelectionChanged: handleRowSelectionChanged,
      onRowDoubleClicked,
      ...persistedRowGroupsOpened.gridOptionsOverlay,
      autoGroupColumnDef,
      rowGroupPanelShow: 'always',
      showOpenedGroup: true,
      groupRemoveLowestSingleChildren: true,
      groupMaintainOrder: false,
      rowSelection: DEFAULT_BLOTTER_SELECTION_MULTI_PARAMS,
      getContextMenuItems,
    },
  });

  const { expandAllGroups, collapseAllGroups, setRowGroupColumns, exportDataAsCSV } = blotterTable;

  const { updateGroupingOnColumnsChanged, groupingToggleButtons } = useBlotterGroupingToggling({
    blotterName: 'customer balances',
    initialColumns: persistedBlotterTable.columns,
    setRowGroupColumns,
    groupings: GROUPINGS,
    allowNoGrouping: true,
  });

  const handleCloneTab = useDynamicCallback(() => {
    onCloneTab?.(
      filterResults.filter,
      blotterTable.getColumns(),
      persistedRowGroupsOpened.getRowGroupsOpenedState(),
      showZeroBalances
    );
  });

  const handleClickExport = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.ExportRows);
    exportDataAsCSV({
      fileName: createCSVFileName({
        name: 'Customer Balances',
        tabLabel: tab.label,
      }),
    });
  });

  /**
   * We let the blotter know explicitly when the request changes.
   */
  useEffect(() => {
    blotterTable.onRequestChanged(request);
  }, [request, blotterTable]);

  const extrasMenuPopover = useBlotterTableExtrasMenu();

  const updateShowZeroBalances = useDynamicCallback((showZeroBalances: boolean) => {
    onUpdateTab({ ...tab, showZeroBalances });
  });

  const setShowAllDecimals = useDynamicCallback((showAllDecimals: boolean) => {
    onUpdateTab({ ...tab, showAllDecimals });
  });

  return (
    <>
      <BlotterTableFilters
        {...filterBuilderAccordion}
        {...blotterTable.blotterTableFiltersProps}
        accordionBodyPrefix={groupingToggleButtons}
        prefix={
          <>
            <IconButton
              icon={IconName.ListExpand}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={expandAllGroups}
              data-testid="button-expand-all"
            />
            <IconButton
              icon={IconName.ListCollapse}
              size={FormControlSizes.Small}
              variant={ButtonVariants.Default}
              onClick={collapseAllGroups}
              data-testid="button-collapse-all"
            />
          </>
        }
        suffix={
          <>
            <BlotterTableExtrasMenu {...extrasMenuPopover}>
              <Toggle
                size={FormControlSizes.Small}
                checked={showZeroBalances}
                onChange={updateShowZeroBalances}
                label="Show Zero Balances"
              />
              <Toggle
                size={FormControlSizes.Small}
                checked={showAllDecimals}
                onChange={setShowAllDecimals}
                label="Show All Decimals"
                data-testid="show-all-decimals-toggle"
              />
              <Button
                startIcon={IconName.Duplicate}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleCloneTab}
              >
                Clone Tab
              </Button>
              <Button
                startIcon={IconName.DocumentDownload}
                variant={ButtonVariants.Default}
                size={FormControlSizes.Small}
                onClick={handleClickExport}
              >
                Export
              </Button>
            </BlotterTableExtrasMenu>
          </>
        }
      />
      <InlineFormattedNumberContext.Provider value={useMemo(() => ({ showAllDecimals }), [showAllDecimals])}>
        {/* hiding the blotter table instead of unmounting as it can get out of sync with the associated hooks otherwise, eventually instead we should have blotter table itself show the message much like it does when there are no rows. */}
        <BlotterTable {...blotterTable} hidden={!hasRequiredFilterSelections} />
      </InlineFormattedNumberContext.Provider>
      {!hasRequiredFilterSelections && (
        <BlotterTableOverlay>
          <h2 data-testid="select-counterparty-message">Please select a Counterparty</h2>
        </BlotterTableOverlay>
      )}
      {hasRequiredFilterSelections && <BalancesBlotterSummaryLine rows={selectedRows} />}
      {jsonModal}
    </>
  );
};

function onlyServerKeys(filter: CustomerBalancesFilter | undefined): CustomerBalancesFilter {
  if (filter == null) {
    return {};
  }

  return pick(filter, ['Currencies', 'Counterparties']);
}
