import { type Allocation, POST, request, useDynamicCallback, useEndpointsContext } from '@talos/kyoko';
import { v4 as uuid } from 'uuid';

export interface ResolveBreakParams {
  CheckpointID: string;
  MatchID?: string;
  AvgCost?: string;
  AvgCostCurrency?: string;
  /** If allocations are not passed, the Resolution is inferred by the backend to be "Ignore" */
  Allocation?: Allocation[];
  Comments?: string;
  TransactTime?: string;
}

interface ResolveBreakRequest {
  RequestID: string;
  CheckpointID: string;
  MatchID?: string;
  AvgCost?: string;
  AvgCostCurrency?: string;
  Allocation?: { SubAccount: string; Amount: string }[];
  Comments?: string;
  TransactTime?: string;
}

interface AddCommentToBreakRequest {
  RequestID: string;
  MatchID: string;
  Comments: string;
}

export const useSubAccountReconRequests = () => {
  const { orgApiEndpoint } = useEndpointsContext();

  const resolveBreak = useDynamicCallback((params: ResolveBreakParams) => {
    const requestID = uuid();
    const requestAllocations = params.Allocation?.map(alloc => ({ SubAccount: alloc.subAccount, Amount: alloc.value }));
    const body: ResolveBreakRequest = {
      ...params,
      RequestID: requestID,
      Allocation: requestAllocations,
    };
    return request(POST, `${orgApiEndpoint}/subaccounts/recon/resolve`, body);
  });

  const addCommentToBreak = useDynamicCallback((matchID: string, comments: string) => {
    const body: AddCommentToBreakRequest = {
      RequestID: uuid(),
      MatchID: matchID,
      Comments: comments,
    };
    return request(POST, `${orgApiEndpoint}/subaccounts/recon/comment`, body);
  });

  return {
    resolveBreak,
    addCommentToBreak,
  };
};
