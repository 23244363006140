import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  formattedDateForSubscription,
  HStack,
  usePortal,
  VStack,
} from '@talos/kyoko';
import { BlotterAwareBox } from 'containers/Trading/styles';
import { subDays } from 'date-fns';
import { useState } from 'react';
import type { TradeTabProps } from '.';
import { DEFAULT_TRADE_COLUMNS } from './columns';
import { FilteredTrades } from './FilteredTrades';
import { TRADES_BLOTTER_PREFIX } from './tokens';

const RECENT_TAB_DEFAULTS: TradeTabProps = {
  defaultFilter: {
    StartDate: formattedDateForSubscription(subDays(new Date(), 1)),
  },
  defaultColumns: DEFAULT_TRADE_COLUMNS,
};
/** Simplified version of the Trades components that excludes the Inner Tabs */
export const RecentTrades = ({ blotterId }: { blotterId: string }) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const [showAllDecimals, setShowAllDecimals] = useState(false);
  return (
    <VStack alignItems="stretch" gap="spacingTiny" h="100%" w="100%">
      <HStack
        minHeight="40px"
        w="100%"
        alignItems="center"
        justifyContent="right"
        gap="spacingComfortable"
        background="backgroundContent"
        px="spacingComfortable"
      >
        <Box ref={filtersContainerRef} />
      </HStack>{' '}
      <AccordionGroup key={blotterId}>
        <BlotterAwareBox flex="auto">
          <FilteredTrades
            key={blotterId}
            showAllDecimals={showAllDecimals}
            setShowAllDecimals={setShowAllDecimals}
            blotterPersistID={`${TRADES_BLOTTER_PREFIX}/${blotterId}`}
            defaultColumns={RECENT_TAB_DEFAULTS.defaultColumns}
            defaultFilter={RECENT_TAB_DEFAULTS.defaultFilter}
            onCloneTab={() => ({})}
            initialIsOpen={false}
            showCreateManualTrade={true}
            showAPIOrdersToggle={true}
          />
        </BlotterAwareBox>
      </AccordionGroup>
    </VStack>
  );
};
