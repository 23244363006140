import {
  BlotterDensity,
  BlotterTable,
  CUSTOMER_MARKET_ACCOUNT,
  IconName,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  PORTFOLIO_TRADING_ACCOUNTS,
  columnTypes,
  useConstant,
  useDynamicCallback,
  useWSFilterPipe,
  useWsBlotterTable,
  type Column,
  type CompositePipeFunction,
  type Customer,
  type Market,
  type MarketAccount,
  type MarketTypeEnum,
  type UseWsBlotterTableProps,
} from '@talos/kyoko';
import type { GridApi, GroupCellRendererParams, IRowNode } from 'ag-grid-community';
import { first } from 'lodash';
import { useCallback, useMemo } from 'react';
import { map, pipe } from 'rxjs';
import { HeaderMarketAccountLogo } from '../components/HeaderMarketAccountLogo';
import { HeaderMarketLogo } from '../components/HeaderMarketLogo';
import type { DestinationAccount } from '../types';

interface TreasuryLinkEntitiesBlotterProps extends Partial<UseWsBlotterTableProps<any, MarketAccount>> {
  customersByName?: Map<string, Customer>;
  marketsByName?: Map<string, Market>;
  marketType?: MarketTypeEnum;
  onClickRow: (entity?: MarketAccount) => void;
  filterText: string;
}

const getUniqueKey = (ma: MarketAccount) => ma.MarketAccountID.toString();

const getColumns = (
  isCustomersColumns: boolean,
  onClickRow: TreasuryLinkEntitiesBlotterProps['onClickRow']
): Column[] => {
  const columns: Column[] = [];

  if (isCustomersColumns) {
    columns.push({
      field: 'DisplayName',
      type: 'counterparty',
      title: '',
      hide: true,
      rowGroup: true,
      params: {
        showIconPrefix: true,
      },
    });

    columns.push({
      field: 'SourceAccountID',
      title: '',
      type: 'custom',
      width: 400,
      params: {
        valueGetter: ({ node }: { node: IRowNode<MarketAccount> }) => {
          // We want to show the SourceAccountID of rows only for expanded group items.
          // We check whether the parent is expanded for this check.
          if (!node.group && node.parent?.expanded) {
            return node.data?.SourceAccountID;
          }
        },
      },
    });
  } else {
    columns.push({
      field: 'Market',
      type: 'custom',
      title: '',
      hide: true,
      rowGroup: true,
      params: {
        showIconPrefix: true,
        cellRenderer: ({ data, node }: GroupCellRendererParams<DestinationAccount>) => {
          if (node.group) {
            const marketName = first(node.allLeafChildren)?.data?.Market;
            return marketName ? <HeaderMarketLogo marketName={marketName} /> : null;
          }

          const marketAccName = data?.Name;
          if (marketAccName != null) {
            return <HeaderMarketAccountLogo marketAccountName={marketAccName} />;
          }
        },
      },
    });
    columns.push({
      field: 'Name',
      title: '',
      type: 'custom',
      width: 400,
      params: {
        valueGetter: ({ node }: { node: IRowNode<DestinationAccount> }) => {
          // We want to show the SourceAccountID of rows only for expanded group items.
          // We check whether the parent is expanded for this check.
          if (!node.group && node.parent?.expanded) {
            return node.data?.Name;
          }
        },
      },
    });
  }

  columns.push({ type: 'filler', id: 'filler' });
  columns.push({
    id: 'edit',
    type: 'iconButton',
    pinned: 'right',
    width: 60,
    params: ({ node }: { node: IRowNode<MarketAccount> }) => {
      if (node.group) {
        return { onClick: () => {} };
      }
      return {
        onClick: ({ node }: { node: IRowNode<MarketAccount> }) => {
          onClickRow(node.data);
        },
        icon: IconName.Pencil,
      };
    },
  });

  return columns;
};

export const TreasuryLinkEntitiesBlotter = ({
  customersByName,
  marketsByName,
  onClickRow,
  marketType,
  filterText,
}: TreasuryLinkEntitiesBlotterProps) => {
  const columns = useConstant(getColumns(marketType == null, onClickRow));

  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        title: '',
        editable: false,
        hide: true,
        suppressColumnsToolPanel: true,
        width: 250,
        params: {
          suppressCount: true,
        },
      }),
    []
  );

  const filterFunc = useCallback(
    (ma: MarketAccount | DestinationAccount) => {
      const market = marketsByName?.get(ma.Market);
      const customer = customersByName?.get(ma.Counterparty);
      if (
        ma.Status === MarketAccountStatusEnum.Inactive ||
        (marketType != null && market != null && market.Type !== marketType) ||
        // [DEAL-4730] If the MA type is Customer, we expect the customer record to exist.
        (ma.Type === MarketAccountTypeEnum.Customer && customer == null)
      ) {
        return false;
      }
      return true;
    },
    [customersByName, marketType, marketsByName]
  );

  const filterPipe = useWSFilterPipe<MarketAccount>({ getUniqueKey, filterFunc });

  const addDisplayNamePipe = useConstant(
    pipe(
      filterPipe,
      map(result => {
        return {
          ...result,
          data: result.data.map(ma => {
            const customer = customersByName?.get(ma.Counterparty);
            return {
              ...ma,
              // [DEAL-4730] If the MA type is Customer, we expect the customer record to exist.
              DisplayName:
                ma.Type === MarketAccountTypeEnum.Customer
                  ? customer?.DisplayName || customer?.Name || 'Unknown Customer'
                  : ma.DisplayName || ma.Name || 'Unknown Market Account',
            };
          }),
        };
      })
    ) satisfies CompositePipeFunction<MarketAccount>
  );

  const handleOnRowClicked = useDynamicCallback(({ node, api }: { node: IRowNode<MarketAccount>; api: GridApi }) => {
    if (!node.group) {
      return;
    }
    if (node.expanded) {
      api.setRowNodeExpanded(node, false);
    } else {
      api.setRowNodeExpanded(node, true);
    }
  });

  const handleOnRowDoubleClicked = useDynamicCallback(({ node }: { node: IRowNode<MarketAccount>; api: GridApi }) => {
    if (!node.group) {
      onClickRow(node.data);
    }
  });

  const isCustomerRequest = marketType == null;
  const blotterTable = useWsBlotterTable({
    initialRequest: {
      name: isCustomerRequest ? CUSTOMER_MARKET_ACCOUNT : PORTFOLIO_TRADING_ACCOUNTS,
      tag: 'TreasuryLinkEntitiesBlotter',
    },
    pipe: addDisplayNamePipe,
    rowID: 'Name',
    columns,
    density: BlotterDensity.VeryComfortable,
    quickSearchParams: {
      filterText,
    },
    gridOptions: {
      groupDisplayType: 'singleColumn',
      groupRemoveSingleChildren: true,
      showOpenedGroup: true,
      autoGroupColumnDef,
      serverSideSortAllLevels: true,
      headerHeight: 0,
      onRowClicked: handleOnRowClicked,
      onRowDoubleClicked: handleOnRowDoubleClicked,
    },
  });

  return <BlotterTable {...blotterTable} />;
};
