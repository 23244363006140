import { MixpanelEvent } from '@talos/kyoko';
import { useTradingSettings } from '../../../providers/TradingSettingsContext';
import { TradingSettingCheckbox } from '../Trading/TradingSettingCheckbox';

export function BBOPriceOffsets() {
  const { alwaysUseBBOForPriceOffsets, setAlwaysUseBBOForPriceOffsets } = useTradingSettings();

  return (
    <TradingSettingCheckbox
      id="bboPriceOffsets"
      isEnabled={!!alwaysUseBBOForPriceOffsets}
      onChecked={setAlwaysUseBBOForPriceOffsets}
      mixPanelEvent={MixpanelEvent.EnableBBOForPriceOffsets}
      toastDisplayName="Price Offset Use Best Bid Offer"
      displayName="Price Offset Use Best Bid Offer"
      displayDescription="When enabled, Price Offset will use the Best Bid Offer rate when setting the Limit Px, regardless of what is shown on the top of the order form."
    />
  );
}
