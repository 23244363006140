import type { PropsWithChildren } from 'react';
import { useOverflowHandler } from '../../../hooks';
import { Box, type BoxProps } from '../../Core';
import type { TooltipProps } from '../../Tooltip';
import { Tooltip } from '../../Tooltip';

/** This is very similar in nature to the styled-component EllipsisText, but that had limitations getting it to work
 * with the auto-tooltip you ideally want whenever you're ellipsizing text.  This allows that functionality as a built-in behavior.
 *
 * Once we're confident on this component, we could consider removing EllipsisText and replacing it with this.
 */
export const EllipsisBoxWithTooltip = ({
  children,
  tooltipTestId,
  tooltipZIndex,
  tooltipProps,
  ...props
}: PropsWithChildren<
  BoxProps & {
    'data-testid'?: string;
    tooltipTestId?: string;
    tooltipZIndex?: number;
    tooltipProps?: Partial<TooltipProps>;
  }
>) => {
  const { isOverflowing, ref } = useOverflowHandler();

  return (
    <Box maxWidth="100%" overflow="hidden">
      {/* At present this cannot be replaced with JITTooltip because of the inner/outer Box relationship */}
      <Tooltip
        delay={500}
        tooltip={children}
        placement="bottom"
        trigger={isOverflowing ? 'hover' : ''}
        tooltipTestID={tooltipTestId}
        zIndex={tooltipZIndex}
        {...tooltipProps}
      >
        <Box ref={ref} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" {...props}>
          {children}
        </Box>
      </Tooltip>
    </Box>
  );
};
